import queryString from "query-string";
import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { _post } from "../utils/services/api.service";
import { getUserDetails, isLoggedIn } from "../utils/services/methods";
import { navigate } from "gatsby";

const Whatsapp = props => {
  const [loading, setLoading] = useState(true);
  const acumenWhatsApp = process.env.GATSBY_acumen_whatsapp;

  const addWhatsApp = async (number, browserLanguage) => {
    const userDetails = getUserDetails();
    const url = `https://wa.me/${acumenWhatsApp}`;
    const requestParams = {
      email: userDetails.email,
      number,
      browserLanguage,
    };

    try {
      const response = await _post({
        endpoint: "whatsapp",
        args: requestParams,
      });

      if (response.success) {
        window.location.replace(url);
      } else {
        setLoading(false);
        alert(response.errors[0].message);
      }
    } catch (error) {
      setLoading(false);
      alert("An unexpected error occurred. Please try again later.");
      console.error(error);
    }
  };

  useEffect(() => {
    const userLanguage = (navigator.language || navigator.userLanguage).split(
      "-"
    )[0];
    const queryParams = queryString.parse(props.location.search);
    let number = queryParams?.number?.trim();

    if (number) {
      number = number.replace("whatsapp:", "").replace(" ", "");

      if (!number.startsWith("+")) {
        number = `+${number}`;
      }
      const validNumber = /^\+\d+$/.test(number);
      if (!validNumber) {
        alert("Invalid phone number format.");
        setLoading(false);
        return;
      }
    }
    try {
      const pendingWhatsApp = JSON.parse(
        localStorage.getItem("pendingWhatsApp")
      );
      if (isLoggedIn() && pendingWhatsApp?.number) {
        number = pendingWhatsApp.number;
        localStorage.removeItem("pendingWhatsApp");
        addWhatsApp(number, userLanguage);
      } else if (isLoggedIn() && number) {
        addWhatsApp(number, userLanguage);
      } else if (number) {
        localStorage.setItem("pendingWhatsApp", JSON.stringify({ number }));
        window.location.replace(
          `${process.env.GATSBY_login_url}sso?redirect=${window.location.origin}/whatsapp`
        );
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("LocalStorage error:", error);
      setLoading(false);
      alert("Invalid phone number format.");
      navigate("/");
    }
  }, [props.location.search]);

  return <Layout loading={loading}></Layout>;
};

export default Whatsapp;
